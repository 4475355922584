import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'
import couponApi from '../../../api/coupons'
import CouponApiData from '../../../types/CouponApiData'
import { entitlementDurationOptions } from '../CouponEditor/CouponEditor'
import CheckBox from '../../../components/Checkbox'
import { Button } from '../../../design-system/utils'

export enum CouponMediaSource {
  eventPartner = 'Event_Partners',
  coopahEmails = 'Coopah_Emails',
  charityPartners = 'Charity_Partners',
  brandPartners = 'Brand_Partners',
  influencersTier2Plus = 'Influencers_Tier2+',
  coopahCampaigns = 'Coopah_Campaigns',
  productExperience = 'Product_Experience',
  coopahRs = 'Coopah_RS',
  coopahContent = 'Coopah_Content',
  clubPartners = 'Club_Partners',
  influencersTier1 = 'Influencers_Tier1',
  staffReferral = 'Staff_Referral',
  bookingPartners = 'Booking_Partners',
  userInvite = 'User_Invite',
  organicInstagram = 'Organic_Instagram',
  facebookGroups = 'Facebook_Groups',
  paidSearch = 'Paid_Search',
  paidSocial = 'Paid_Social',
  liveEvents = 'Live_Events',
  sms = 'SMS',
  coopahWebsite = 'Coopah_Website',
  parthnerContent = 'Partner_Content',
  partnerMicrosite = 'Partner_Microsite',
}

export enum CouponStatus {
  donated = 'donated',
  limited = 'limited',
  subscribed = 'subscribed',
}

export enum RevenuecatEntitlementDuration {
  daily = 'daily',
  threeDay = 'three_day',
  weekly = 'weekly',
  monthly = 'monthly',
  twoMonth = 'two_month',
  threeMonth = 'three_month',
  sixMonth = 'six_month',
  yearly = 'yearly',
  lifetime = 'lifetime',
}

interface Coupon {
  id: string
  title: string
  code: string
  startDateTime: Date
  endDateTime?: Date
  maxRedemptions: number
  timesRedeemed: number
  appleCouponCode?: string
  googleCouponCode?: string
  entitlement?: string
  group?: string
  campaign?: string
  mediaSource?: CouponMediaSource
  status: CouponStatus
  entitlementDuration?: RevenuecatEntitlementDuration
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function Row(props) {
  const { row, onUpdate, search, selectedCoupons, toggleSelection } = props
  const [open, setOpen] = useState(false)
  const classes = useRowStyles()

  useEffect(() => {
    if (!open && search) {
      setOpen(true) // open the row by default when admin searches for a coupon
    }
  }, [search, open])

  const deleteCoupon = (id) => {
    couponApi.deleteCoupon(id).then(() => {
      toast.success('Coupon deleted')
      onUpdate()
    })
  }

  const copyCouponsOfGroup = () => {
    copy(row.coupons.map((c) => c?.code).join('\n'))
    toast.success('Coupons copied to clipboard')
  }
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <IconButton onClick={() => copyCouponsOfGroup()}>
            <ContentCopyOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.group}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Max Redemptions</TableCell>
                    <TableCell>Time Redeemed</TableCell>
                    <TableCell>Apple Coupon</TableCell>
                    <TableCell>Google Coupon</TableCell>
                    <TableCell>Revenuecat Entitlement Duration</TableCell>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Media Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!row?.coupons?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        No coupons found
                      </TableCell>
                    </TableRow>
                  )}
                  {row?.coupons?.map((coupon) => (
                    <TableRow key={coupon.id}>
                      <TableCell component="th" scope="row">
                        <CheckBox
                          checked={!!selectedCoupons[coupon.id]}
                          toggleCheck={(e) =>
                            toggleSelection(
                              coupon.id,
                              !selectedCoupons[coupon.id]
                            )
                          }
                        />
                        <IconButton onClick={() => deleteCoupon(coupon?.id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            copy(coupon?.code)
                            toast.success('Copied!')
                          }}
                        >
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{coupon?.title}</TableCell>
                      <TableCell>{coupon?.code}</TableCell>
                      <TableCell>
                        {moment(coupon?.startDateTime).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </TableCell>
                      <TableCell>
                        {coupon?.endDateTime
                          ? moment(coupon?.endDateTime).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{coupon?.status || 'N/A'}</TableCell>
                      <TableCell>{coupon?.maxRedemptions || 'N/A'}</TableCell>
                      <TableCell>{coupon?.timesRedeemed || 'N/A'}</TableCell>
                      <TableCell>{coupon?.appleCouponCode || 'N/A'}</TableCell>
                      <TableCell>{coupon?.googleCouponCode || 'N/A'}</TableCell>
                      <TableCell>
                        {entitlementDurationOptions?.find(
                          (i) => i?.field === coupon?.entitlementDuration
                        )?.label || 'N/A'}
                      </TableCell>
                      <TableCell>{coupon?.campaign || 'N/A'}</TableCell>
                      <TableCell>{coupon?.mediaSource || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function CollapsibleTable({ coupons, onUpdate, search }) {
  const [selectedCoupons, setSelectedCoupons] = useState({})

  const toggleSelection = useCallback((couponId, checked) => {
    setSelectedCoupons((prevState) => ({
      ...prevState,
      [couponId]: checked,
    }))
  }, [])

  const copySelectedCouponsToClipboard = () => {
    const couponsList = Object.values(coupons).flat() as Coupon[]

    const couponCodes = couponsList.filter((c) => selectedCoupons[c.id])

    copy(couponCodes.map((c) => c.code).join('\n'))
    toast.success('Selected Coupons copied to clipboard')
    setSelectedCoupons({})
  }

  const formattedCoupons = useMemo(() => {
    if (!coupons) return []
    return Object.entries(coupons).map(([group, couponsList]) => {
      const list = couponsList as Array<CouponApiData>
      return {
        group,
        coupons: list?.filter(
          (c) =>
            c.code.toLowerCase().includes(search.toLowerCase()) ||
            c.title.toLowerCase().includes(search.toLowerCase())
        ),
      }
    })
  }, [coupons, search])

  return (
    <TableContainer component={Paper}>
      {!!Object.keys(selectedCoupons).length && (
        <Button onClick={copySelectedCouponsToClipboard}>Copy Selected</Button>
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>Copy coupons</TableCell>
            <TableCell width="90%">Group</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedCoupons.map((row) => (
            <Row
              key={row.group}
              row={row}
              onUpdate={onUpdate}
              search={search}
              selectedCoupons={selectedCoupons}
              toggleSelection={toggleSelection}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
