export enum CouponStatus {
  donated = 'donated',
  limited = 'limited',
}

export enum RevenuecatEntitlementDuration {
  daily = 'daily',
  threeDay = 'three_day',
  weekly = 'weekly',
  monthly = 'monthly',
  twoMonth = 'two_month',
  threeMonth = 'three_month',
  sixMonth = 'six_month',
  yearly = 'yearly',
  lifetime = 'lifetime',
}

export enum AppleGoogleCouponCodes {
  runone = 'RUNONE',
  megatwo = 'MEGATWO',
  coopahthree = 'COOPAHTHREE',
  supertwoweek = 'SUPERTWOWEEK',
  twentywithtwo = 'TWENTYWITHTWO',
  onefor50 = 'ONEFOR50',
  fifteenOFF = '15OFF',
  twentyOFF = '20OFF',
  three25off = 'THREE25OFF',
  megaonefor50 = 'MEGAONEFOR50',
  oneforone = 'ONEFORONE',
  coopah2023 = 'COOPAH2023',
}

export enum CouponMediaSource {
  eventPartner = 'Event_Partners',
  coopahEmails = 'Coopah_Emails',
  charityPartners = 'Charity_Partners',
}

type CouponApiData = {
  title: string
  code: string
  startDateTime: string
  endDateTime: string
  maxRedemptions: number
  appleCouponCode: string
  googleCouponCode: string
  entitlementDuration: RevenuecatEntitlementDuration
  status: CouponStatus
  mediaSource: CouponMediaSource
  campaign: string
  quantity?: number
  group?: string
}

export default CouponApiData
