export const couponStatusOptions = [
  { field: 'limited', label: 'Limited' },
  { field: 'subscribed', label: 'Subscribed' },
]

export enum CouponMediaSource {
  eventPartner = 'Event_Partners',
  coopahEmails = 'Coopah_Emails',
  charityPartners = 'Charity_Partners',
  brandPartners = 'Brand_Partners',
  influencersTier2Plus = 'Influencers_Tier2+',
  coopahCampaigns = 'Coopah_Campaigns',
  productExperience = 'Product_Experience',
  coopahRs = 'Coopah_RS',
  coopahContent = 'Coopah_Content',
  clubPartners = 'Club_Partners',
  influencersTier1 = 'Influencers_Tier1',
  staffReferral = 'Staff_Referral',
  bookingPartners = 'Booking_Partners',
  userInvite = 'User_Invite',
  organicInstagram = 'Organic_Instagram',
  facebookGroups = 'Facebook_Groups',
  paidSearch = 'Paid_Search',
  paidSocial = 'Paid_Social',
  liveEvents = 'Live_Events',
  sms = 'SMS',
  coopahWebsite = 'Coopah_Website',
  parthnerContent = 'Partner_Content',
  partnerMicrosite = 'Partner_Microsite',
}

export const mediaSourceOptions = [
  { field: CouponMediaSource.eventPartner, label: 'Event Partners' },
  { field: CouponMediaSource.coopahEmails, label: 'Coopah Emails' },
  { field: CouponMediaSource.charityPartners, label: 'Charity Partners' },
  { field: CouponMediaSource.brandPartners, label: 'Brand Partners' },
  {
    field: CouponMediaSource.influencersTier2Plus,
    label: 'Influencers Tier 2+',
  },
  { field: CouponMediaSource.coopahCampaigns, label: 'Coopah Campaigns' },
  { field: CouponMediaSource.productExperience, label: 'Product Experience' },
  { field: CouponMediaSource.coopahRs, label: 'Coopah RS' },
  { field: CouponMediaSource.coopahContent, label: 'Coopah Content' },
  { field: CouponMediaSource.clubPartners, label: 'Club Partners' },
  { field: CouponMediaSource.influencersTier1, label: 'Influencers Tier 1' },
  { field: CouponMediaSource.staffReferral, label: 'Staff Referral' },
  { field: CouponMediaSource.bookingPartners, label: 'Booking Partners' },
  { field: CouponMediaSource.userInvite, label: 'User Invite' },
  { field: CouponMediaSource.organicInstagram, label: 'Organic Instagram' },
  { field: CouponMediaSource.facebookGroups, label: 'Facebook Groups' },
  { field: CouponMediaSource.paidSearch, label: 'Paid Search' },
  { field: CouponMediaSource.paidSocial, label: 'Paid Social' },
  { field: CouponMediaSource.liveEvents, label: 'Live Events' },
  { field: CouponMediaSource.sms, label: 'SMS' },
  { field: CouponMediaSource.coopahWebsite, label: 'Coopah Website' },
  { field: CouponMediaSource.parthnerContent, label: 'Partner Content' },
  { field: CouponMediaSource.partnerMicrosite, label: 'Partner Microsite' },
]

export default couponStatusOptions
